import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Index from '../views/index.vue'
import login from '../views/login.vue'
import messageShow from '../components/MessageShow.vue'
import messageShowNew from '../components/MessageShowNew.vue'
import NoteShow from '../components/note/NoteShow.vue'
import message from '../components/Message.vue'
import PassComponent from '../components/PassComponent.vue'
import ZhangBen from '../components/ZhangBen.vue'
import MessageTree from '../components/MessageTree.vue'
import testVue from '../components/test.vue'
import MessageTreeNew from '../components/MessageTreeNew.vue'
import PassNewComponent from '../components/PassNewComponent.vue'

Vue.use(VueRouter)

const routes = [
	// 登录页面
	{
		path: '/login',
		name: 'login',
		component: login
	},
	// 笔记编辑
	{
		path: '/SzZb',
		name: 'SzZb',
		component: () => import('../components/Sz/SzZb.vue')
	},
	// 菜单页面, 主页		--老版地址, 改动后一律按照新版路由地址走
	{
		path: '/menu',
		name: 'indexOld',
		redirect: () => {
			return '/MessageTreeNew';
		}
	},
	{
		path: '/',
		name: 'index',
		redirect: () => {
			return '/NoteTitle';
		}
	},
	// 笔记查看--新业务, 使用新窗口打开
	{
		path: '/NoteShow',
		name: 'NoteShow',
		component: NoteShow,
		meta: {
			keepAlive: false // 禁用缓存
		}
	},
	// 笔记查看, 使用新窗口打开
	{
		path: '/messageShow',
		name: 'messageShow',
		component: messageShow,
	},
	// 笔记查看--新业务, 使用新窗口打开
	{
		path: '/messageShowNew',
		name: 'messageShowNew',
		component: messageShowNew,
		meta: {
			keepAlive: false // 禁用缓存
		}
	},
	// 笔记编辑
	{
		path: '/message',
		name: 'message',
		component: message,
	},
	// 笔记编辑--新note模块
	{
		path: '/Note',
		name: 'Note',
		component: () => import('../components/note/Note.vue'),
	},
	// 亚创问题池模块
	{
		path: '/YcProblem',
		name: 'YcProblem',
		component: () => import('../components/Yc/YcProblem.vue'),
	},
	// 亚创问题池模块--新
	{
		path: '/YcProblemNew',
		name: 'YcProblemNew',
		component: () => import('../components/Yc/YcProblemNew.vue'),
	},
	// 亚创问题池报表模块--问题归类按时间统计--新
	{
		path: '/YcProblemTypeByTime',
		name: 'YcProblemTypeByTime',
		component: () => import('../components/Yc/report/YcProblemTypeByTime.vue'),
	},
	// 问题池中指定时间内工程师处理问题数量, 按数量倒序, 只统计售后部
	{
		path: '/selectYcProblemByPersonnel',
		name: 'selectYcProblemByPersonnel',
		component: () => import('../components/Yc/report/selectYcProblemByPersonnel.vue'),
	},
	// 笔记一级标题组件
	{
		path: '/MessageTree',
		name: 'MessageTree',
		component: () => import('../components/MessageTree.vue')
	},
	// 笔记一级标题组件--新note模块
	{
		path: '/NoteTitleByUid',
		name: 'NoteTitleByUid',
		component: () => import('../components/note/noteTitileByUid.vue')
	},
	// 笔记一级标题组件--新版查看组件
	{
		path: '/MessageTreeNew',
		name: 'MessageTreeNew',
		component: () => import('../components/MessageTreeNew.vue')
	},
	// 笔记摘要信息组件--新版查看组件note
	{
		path: '/NoteTitle',
		name: 'NoteTitle',
		component: () => import('../components/note/NoteTitle.vue')
	},
	// 账本组件
	{
		path: '/ZhangBen',
		name: 'ZhangBen',
		component: () => import('../components/ZhangBen.vue')
	},
	// 密码组件
	{
		path: '/PassComponent',
		name: 'PassComponent',
		component: () => import('../components/PassComponent.vue')
	},
	// 密码组件--新
	{
		path: '/PassComponentNew',
		name: 'PassComponentNew',
		component: () => import('../components/PassNewComponent.vue')
	},
	{
		path: '/about',
		name: 'about',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/AboutView.vue')
	},
	//用户组件
	{
		path: '/user',
		name: 'UserComponent',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../components/UserComponent.vue')
	},
	// 测试组件地址
	{
		path: '/test',
		name: 'testComponent',
		component: () => import('../components/test2.vue')
	},
	// 报表组件--账单按时间分类明细统计
	{
		path: '/BillReportForms',
		name: 'BillReportForms',
		component: () => import('../components/BillComponent/BillReportForms.vue')
	},
	// 报表组件--账单按时间统计明细
	{
		path: '/BillReportFormsDataDetail',
		name: 'BillReportFormsDataDetail',
		component: () => import('../components/BillComponent/BillReportFormsDataDetail.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router