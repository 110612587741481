<template>
	<div id="HeaderMenuComponentParentDiv">
		<!-- 布局容器 -->
		<!-- 头部区域 -->
		<el-header style="z-index: 1;">
			<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
				:router='true' id="tou">
				<el-submenu index="2">
					<template slot="title">我的工作台</template>
					<el-menu-item index="PassComponentNew">密码存储-新</el-menu-item>
					<el-menu-item index="PassComponent" :disabled='loginOr'>密码存储-旧</el-menu-item>
					<el-menu-item index="2-3">-----</el-menu-item>
					<el-submenu index="2-4" :disabled='loginOr'>
						<template slot="title">账本相关</template>
						<el-menu-item index="ZhangBen" :disabled='loginOr'><a>账单管理</a></el-menu-item>
						<el-menu-item index="BillReportForms">记账日期分类汇总统计</el-menu-item>
						<el-menu-item index="2-4-2">记账日期分类明细统计</el-menu-item>
						<el-menu-item index="BillReportFormsDataDetail">记账日期明细统计</el-menu-item>
					</el-submenu>
				</el-submenu>
				<el-menu-item index="NoteTitleByUid">发布笔记</el-menu-item>
				<el-menu-item index="NoteTitle">查看笔记</el-menu-item>
				<el-menu-item index="SzZb">三战战报</el-menu-item>
				<!-- <el-menu-item index="ZhangBen" :disabled='loginOr'><a>账单管理</a></el-menu-item> -->
				<!-- <el-menu-item index="YcProblem" :disabled='loginOr'><a>问题池</a></el-menu-item> -->
				
				<el-submenu index="2-5" :disabled='loginOr'>
					<template slot="title">问题池</template>
					<el-submenu index="2-6" :disabled='loginOr'>
						<template slot="title">报表统计</template>
						<el-menu-item index="YcProblemTypeByTime" :disabled='loginOr'><a>问题归类统计</a></el-menu-item>
						<el-menu-item index="selectYcProblemByPersonnel" :disabled='loginOr'><a>工程师处理问题数量统计</a></el-menu-item>
					</el-submenu>
					<el-menu-item index="YcProblem" :disabled='loginOr'><a>问题池</a></el-menu-item>
					<el-menu-item index="YcProblemNew" :disabled='loginOr'><a>问题池_新</a></el-menu-item>
				</el-submenu>
				
				<el-menu-item index="test"><a>测试模块</a></el-menu-item>
				<el-menu-item index="user" id="userBall">
					<!-- <a>{{loginOrText}}</a> -->
					<template slot="title">
						<el-popover placement="top-start" title="" width="60" trigger="hover" :value='popoverUserShow' v-model="popoverUserShow">
							<template>
								<el-link disabled>默认链接</el-link>
								<el-divider direction="vertical"></el-divider>
								<el-button type="primary" size="mini" width="60" @click="quit">注销</el-button>
							</template>
							<div slot="reference">{{loginOrText}}</div>
						</el-popover>
					</template>
				</el-menu-item>
			</el-menu>
		</el-header>
	</div>
</template>

<script>
	// 头部导航栏组件
	import jsUtils from '../utils/jsUtils.js'
	import loginService from '../service/login.js'

	export default {
		name: 'HeaderMenuComponent',
		data() {
			return {
				activeIndex: '7', // 顶部选项卡默认选中哪一个
				loginOr: true, //是否登录, 用于判断组件是否可用
				loginOrText: '', //导航栏用户显示标题
				popoverUserShow: false, //用户侧边气泡模块是否显示
			}
		},
		components: {},
		created() {
			// console.log("触发created");
		},
		async mounted() {
			// console.log("执行到1");
			// console.log("调用mounted");
			this.$loading({
				text: '加载中'
			});

			//判断是否登录
			await loginService.LoginOrNot().then(resp => {
				// console.log("结果>>", resp);
				if (resp.code === 200) {
					this.loginOr = false;
					let user = window.localStorage.getItem("user") != null ? JSON.parse(window.localStorage
						.getItem("user")) : null;
					this.loginOrText = user != null ? user.username : '';
				} else if (resp.code === 201) {
					this.loginOr = true;
					this.loginOrText = '登录';
				}
			});
			//从本地状态获取导航栏选中的项, 如果没有, 默认选中7
			this.activeIndex = window.localStorage.getItem("menuIndex") == null ? '7' : window.localStorage.getItem(
				"menuIndex");
			//localstorage属性与当前页面地址不一致, 以当前地址为准
			let index = this.$route.path.replace("/", "");
			if (index != window.localStorage.getItem("menuIndex")) {
				this.activeIndex = index;
				window.localStorage.setItem('menuIndex', index);
			}
			document.title = '笔记';
			// this.$loading().close();
			//全局遮罩禁用
			// await jsUtils.loadingAllIsNone(true, 'loadingAll');
		},
		methods: {
			//注销方法
			quit() {
				window.localStorage.clear();
				this.popoverUserShow = false;
				// 重新加载当前页面
				location.reload();
				// this.$message({
				// 	message: "注销成功!",
				// 	type: 'success',
				// 	duration: 1500,
				// 	showClose: true
				// });
			},
			// 顶部选项卡的点击选择触发事件
			handleSelect(key, keyPath) {
				// console.log('选中>>', key);
				window.localStorage.setItem('menuIndex', key);
			}
		},
		beforeDestroy() {
			// console.log("销毁前调用");
		},
		destroyed() {
			// console.log("销毁后调用");
		}
	}
</script>

<style>

</style>